import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/home/Home';
import AuthForm from './components/pages/authForm/AuthForm';
import User from './components/pages/user/User';
import Admin from './components/pages/admin/Admin';
import Settings from './components/pages/settings/Settings';
import Events from './components/pages/events/Events';
import EventDetails from './components/pages/eventDetails/EventDetails';
import { AuthProvider } from './auth/AuthProvider';
import ProtectedRoute from './auth/ProtectedRoute';
import Navbar from './components/navbar/Navbar';

function App() {
    return (
        <AuthProvider>
            <Router>
                <Navbar />
                <div className='content'>
                    <Routes>
                        <Route path='/' exact element={<ProtectedRoute unauthenticatedFallback={<Home />}><User /></ProtectedRoute>} />
                        <Route path='/login' exact element={<AuthForm />} />
                        <Route path='/admin' exact element={<ProtectedRoute><Admin /></ProtectedRoute>} />
                        <Route path='/settings' exact element={<ProtectedRoute><Settings /></ProtectedRoute>} />
                        <Route path='/events' exact element={<ProtectedRoute><Events /></ProtectedRoute>} />
                        <Route path='/event/:id' exact element={<ProtectedRoute><EventDetails /></ProtectedRoute>} />
                        <Route path='/event/create' exact element={<ProtectedRoute><EventDetails createMode={true} /></ProtectedRoute>} />
                    </Routes>
                </div>
            </Router>
        </AuthProvider>
    );
}

export default App;
