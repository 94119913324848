import "./AddEditPanel.css";
import { useEffect, useState } from "react";
import { addPlace, editPlace, getPlaceTypes } from "../../../../services/PlaceService";
import Button from "../../../button/Button";
import TextBox from "../../../textBox/TextBox";
import SelectBox from "../../../selectBox/SelectBox";

export default function AddEditPanel({ selectedPlace, refresh }) {
  const [placeInputs, setPlaceInputs] = useState({ ...selectedPlace });
  const [placeTypes, setPlaceTypes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    getPlaceTypes().then(data => setPlaceTypes(data));
  }, []);

  useEffect(() => {
    if (!selectedPlace?.placeTypeId) {
      selectedPlace.placeTypeId = placeTypes[0]?.id ?? 0;
    }
    setPlaceInputs({ ...selectedPlace });
    setEditMode(!!selectedPlace?.id);
  }, [selectedPlace, placeTypes]);

  const executeAddPlace = async () => {
    if (!placeInputs) {
      return;
    }
    if (!placeInputs.placeTypeId) {
      return;
    }
    placeInputs.coordinates = {
      longitude: placeInputs.longitude,
      latitude: placeInputs.latitude,
    };

    const result = await addPlace(placeInputs);
    if (result) {
      refresh();
      setShowSuccessPopup(true);
    }
  };
  const executeEditPlace = async () => {
    if (!placeInputs) {
      return;
    }

    const result = await editPlace(selectedPlace.id, placeInputs);
    if (result) {
      refresh();
    }
  };

  return (
    <div className="panel-centered add-edit-panel">
      {showSuccessPopup && (
        <div className="success-popup">
          <p>Miejsce zostało przekazane do weryfikacji</p>
          <Button buttonStyle="btn-outline" onClick={() => setShowSuccessPopup(false)}>
            Zamknij
          </Button>
        </div>
      )}

      <h1>{editMode ? "Edytuj miejsce na mapie" : "Dodaj nowe miejsce do mapy"}</h1>

      <TextBox placeholder="Nazwa..." value={placeInputs.name ?? ""} onChange={e => setPlaceInputs({ ...placeInputs, name: e.target.value })} />
      <TextBox placeholder="Adres..." value={placeInputs.address ?? ""} onChange={e => setPlaceInputs({ ...placeInputs, address: e.target.value })} />
      <TextBox
        placeholder="Opis..."
        value={placeInputs.description ?? ""}
        onChange={e => setPlaceInputs({ ...placeInputs, description: e.target.value })}
      />
      <TextBox
        placeholder="Dostępność..."
        value={placeInputs.availability ?? ""}
        onChange={e => setPlaceInputs({ ...placeInputs, availability: e.target.value })}
      />
      <SelectBox
        options={placeTypes?.map(placeType => ({
          value: placeType.id,
          label: placeType.name,
        }))}
        value={placeInputs.placeTypeId ?? placeTypes[0]?.id ?? 0}
        onChange={e => setPlaceInputs({ ...placeInputs, placeTypeId: e.target.value })}
      />

      <Button onClick={editMode ? executeEditPlace : executeAddPlace}>{editMode ? "Edytuj miejsce" : "Dodaj miejsce"}</Button>
    </div>
  );
}
