import { deletePlace } from "../../../../services/PlaceService";
import Button from "../../../button/Button";

export default function DeletePanel({ selectedPlace, refresh }) {
  const executeDeletePlace = async () => {
    if (!selectedPlace?.id) {
      return;
    }

    const result = await deletePlace(selectedPlace.id);
    if (result) {
      refresh();
    }
  };

  return (
    <div className="panel-centered">
      <h1>Czy na pewno chcesz usunąć?</h1>
      <h2 className="place-name">{selectedPlace.name}</h2>
      <Button buttonStyle="btn-delete" onClick={executeDeletePlace}>
        Usuń
      </Button>
    </div>
  );
}
