const domain = "https://miejscufka-dev.mooo.com/api/";

const apiConfig = {
  register: domain + "authentication/register",
  login: domain + "authentication/login",
  logout: domain + "authentication/logout",
  username: domain + "user/username",
  user: domain + "user",
  place: domain + "place",
  placeType: domain + "placetype",
  changeUsername: domain + "user/changeusername",
  changePassword: domain + "user/changepassword",
  changeEmail: domain + "user/changeemail",
  ranking: domain + "place/ranking",
  rating: domain + "rating",
  inactivePlaces: domain + "place/inactive",
  nearestPlace: domain + "place/nearest",
  role: domain + "user/role",
  userId: domain + "user/userid",
  photo: domain + "photo",
  event: domain + "event",
};

export default apiConfig;
