import "./Button.css";
import React from "react";
import { Link } from "react-router-dom";

const STYLES = ["btn-primary", "btn-delete", "btn-outline"];

const Button = React.forwardRef((props, ref) => {
  const buttonStyle = STYLES.includes(props.buttonStyle) ? props.buttonStyle : STYLES[0];
  const buttonClass = `btn ${buttonStyle} ${props.className ?? ""}`;

  const button = (
    <button ref={ref} className={buttonClass} onClick={props.onClick} type={props.type}>
      {props.children}
    </button>
  );

  if (props.link) {
    return <Link to={props.link}>{button}</Link>;
  }

  return button;
});

export default Button;
