import "./AuthForm.css";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import apiConfig from "../../../config/apiConfig";
import { callJsonApi } from "../../../util/ApiCaller";
import { useAuth } from "../../../auth/AuthProvider";
import Button from "../../button/Button";
import TextBox from "../../textBox/TextBox";

export default function AuthForm() {
  const [loginMode, setLoginMode] = useState(true);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setErrorMessage("");
  }, [loginMode]);

  const handleModeSwitch = () => {
    setLoginMode(!loginMode);
  };

  const executeRegister = async e => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage("Błąd: Wprowadzone hasła nie są zgodne.");
      return;
    }

    const requestBody = {
      username: username,
      email: email,
      password: password,
    };

    const { status, data } = await callJsonApi(apiConfig.register, "POST", requestBody);
    if (status === 200) {
      setLoginMode(true);
      setUsername("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      navigate("/login");
    } else {
      setErrorMessage("Rejestracja nieudana: " + data.error);
      return;
    }
  };

  const executeLogin = async e => {
    e.preventDefault();

    const authUsername = await login(username, password);
    if (authUsername !== undefined) {
      navigate("/");
    } else {
      setErrorMessage("Nieprawidłowy login lub hasło");
    }
  };

  return (
    <>
      <div className="auth-container">
        <form className="auth-form" onSubmit={loginMode ? executeLogin : executeRegister}>
          <label htmlFor="username">Login</label>
          <TextBox onChange={e => setUsername(e.target.value)} />

          {!loginMode && (
            <>
              <label htmlFor="email">E-mail</label>
              <TextBox type="email" onChange={e => setEmail(e.target.value)} />
            </>
          )}

          <label htmlFor="password">Hasło</label>
          <TextBox type="password" onChange={e => setPassword(e.target.value)} />

          {!loginMode && (
            <>
              <label htmlFor="confirmPassword">Powtórz hasło</label>
              <TextBox type="password" onChange={e => setConfirmPassword(e.target.value)} />
            </>
          )}

          <div className="auth-buttons">
            <Button>{loginMode ? "Zaloguj" : "Utwórz konto"}</Button>
            <div className="toggle-link">
              {loginMode ? (
                <Link to="#" onClick={handleModeSwitch}>
                  Zarejestruj się
                </Link>
              ) : (
                <Link to="#" onClick={handleModeSwitch}>
                  Zaloguj się
                </Link>
              )}
            </div>
          </div>

          {errorMessage && (
            <div id="error-message" style={{ color: "red" }}>
              {errorMessage}
            </div>
          )}
        </form>
      </div>
    </>
  );
}
