import "./SelectBox.css";

const SelectBox = ({ className, options, value, onChange, readonly }) => {
  if (!onChange) {
    readonly = true;
  }

  let sbClass = "selectBox";
  if (className) {
    sbClass += " " + className;
  }

  return (
    <select className={sbClass} value={value} onChange={onChange} disabled={readonly}>
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SelectBox;