import apiConfig from "../config/apiConfig";
import { callJsonApi } from "../util/ApiCaller";

export async function getPlaces() {
  const { data } = await callJsonApi(apiConfig.place, "GET");
  return data;
}

export async function getInactivePlaces() {
  const { status, data } = await callJsonApi(apiConfig.inactivePlaces, "GET");
  if (status !== 200) {
    return null;
  }
  return data;
}

export async function getPlaceById(placeId) {
  const { status, data } = await callJsonApi(apiConfig.place + `/${placeId}`, "GET");
  if (status !== 200) {
    return null;
  }
  return data;
}

export async function getRanking() {
  const { data } = await callJsonApi(apiConfig.ranking, "GET");
  return data;
}

export async function addPlace(data) {
  const { status } = await callJsonApi(apiConfig.place, "POST", data);
  return status === 201;
}

export async function editPlace(placeId, data) {
  const { status } = await callJsonApi(apiConfig.place + `/${placeId}`, "PUT", data);
  return status === 200;
}

export async function deletePlace(placeId) {
  const { status } = await callJsonApi(apiConfig.place + `/${placeId}`, "DELETE");
  return status === 200;
}

export async function getPlaceRatings(placeId) {
  const { status, data } = await callJsonApi(apiConfig.place + `/${placeId}/ratings`, "GET");
  if (status !== 200) {
    return null;
  }
  return data;
}

export async function ratePlace(placeId, data) {
  const body = {
    placeId: placeId,
    text: data.text,
    score: data.score,
  };
  const { status } = await callJsonApi(apiConfig.rating, "POST", body);
  return status;
}

export async function activatePlace(placeId) {
  const { status } = await callJsonApi(apiConfig.place + `/${placeId}/activate`, "POST");
  return status === 200;
}

export async function getPlaceTypes() {
  const { data } = await callJsonApi(apiConfig.placeType, "GET");
  return data;
}

export async function addPlaceType(placeTypeName) {
  const { status } = await callJsonApi(apiConfig.placeType + `?name=${placeTypeName}`, "POST");
  return status === 201;
}

export async function deletePlaceType(placeId) {
  const { status } = await callJsonApi(apiConfig.placeType + `/${placeId}`, "DELETE");
  return status === 200;
}

export async function nearestPlace(coordinates) {
  const { data } = await callJsonApi(apiConfig.nearestPlace, "POST", coordinates);
  return data;
}

export async function getPlacePhotosIds(placeId) {
  const { data } = await callJsonApi(apiConfig.place + `/${placeId}/photos`, "GET");
  if (!Array.isArray(data)) {
    return null;
  }
  return data.map(photo => photo.id);
}
