import { useNavigate } from "react-router-dom";
import "./PopupUpcomingEvent.css";

const PopupUpcomingEvent = ({ upcomingEvent }) => {
  const navigate = useNavigate();

  if (!upcomingEvent) {
    return <></>;
  }

  const startDate = new Date(upcomingEvent.startDate);
  const endDate = new Date(upcomingEvent.endDate);

  const startDateString = startDate.toLocaleDateString("pl-PL", {
    day: "numeric",
    month: startDate.getMonth() !== endDate.getMonth() ? "numeric" : undefined,
    year: startDate.getFullYear() !== endDate.getFullYear() ? "numeric" : undefined,
  });

  const endDateString = endDate.toLocaleDateString("pl-PL", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

  return (
    <div className="popup-upcoming-event" onClick={() => navigate(`/event/${upcomingEvent.id}`)}>
      <div className="popup-upcoming-event-header">
        <h3>Wydarzenie {startDate > new Date() ? "nadchodzące" : "trwające"}</h3>
      </div>
      <div className="popup-upcoming-event-description">
        <h3>{upcomingEvent.name}</h3>
        <h3>
          {startDate.toLocaleDateString() !== endDate.toLocaleDateString() && <>{startDateString} - </>}
          {endDateString}
        </h3>
      </div>
    </div>
  );
};

export default PopupUpcomingEvent;
