import "./Home.css";
import { useEffect, useState } from "react";
import { getPlaces } from "../../../services/PlaceService";
import PlacesMap from "../../openLayers/placesMap/PlacesMap";

export default function Home() {
  const [places, setPlaces] = useState(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    getPlaces().then(data => setPlaces(data));
  }, []);

  return (
    <>
      <div className="home-container">
        <div className="map-container full">
          <div id="map">
            <PlacesMap places={places} map={map} setMap={setMap} />
          </div>
        </div>
      </div>
    </>
  );
}
