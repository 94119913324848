import apiConfig from "../config/apiConfig";
import { callJsonApi } from "../util/ApiCaller";

export async function uploadPhoto(placeId, photo) {
  const formData = new FormData();
  formData.append("PlaceId", placeId);
  formData.append("File", photo);

  const { status } = await fetch(apiConfig.photo, {
    method: "POST",
    body: formData,
    headers: {
      accept: "*/*",
      SameSite: "none",
    },
    credentials: "include",
  });
  return status === 201;
}

export async function deletePhoto(photoId) {
  const { status } = await callJsonApi(apiConfig.photo + `/${photoId}`, "DELETE");
  return status === 200;
}
