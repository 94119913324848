import "./User.css";
import { useCallback, useEffect, useState } from "react";
import { getPlaces } from "../../../services/PlaceService";
import AddEditPanel from "./panels/AddEditPanel";
import DeletePanel from "./panels/DeletePanel";
import FindNearestPanel from "./panels/FindNearestPanel";
import RankingPanel from "./panels/RankingPanel";
import RatePanel from "./panels/RatePanel";
import PhotosPanel from "./panels/PhotosPanel";
import MapWithPopup from "../../openLayers/MapWithPopup";
import Button from "../../button/Button";

export const userControlPanels = {
  addEdit: {
    isPlaceRequired: false,
  },
  delete: {
    isPlaceRequired: true,
  },
  findNearest: {
    isPlaceRequired: false,
  },
  photos: {
    isPlaceRequired: true,
  },
  ranking: {
    isPlaceRequired: false,
  },
  rate: {
    isPlaceRequired: true,
  },
};

export default function User() {
  const [selectedPlace, setSelectedPlace] = useState({});
  const [places, setPlaces] = useState(null);

  const [activePanel, setActivePanel] = useState(userControlPanels.addEdit);
  const [controlsActive, setControlsActive] = useState(false);

  const openPanel = useCallback(panel => {
    setActivePanel(panel);
    setControlsActive(true);
  }, []);

  const refresh = useCallback(() => {
    setActivePanel(userControlPanels.addEdit);
    setSelectedPlace({});
    getPlaces().then(data => setPlaces(data));
  }, []);

  useEffect(() => {
    refresh();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedPlace?.id && activePanel.isPlaceRequired) {
      setActivePanel(userControlPanels.addEdit);
    }
  }, [selectedPlace, activePanel.isPlaceRequired]);

  const renderPanel = () => {
    switch (activePanel) {
      case userControlPanels.addEdit:
        return <AddEditPanel selectedPlace={selectedPlace} refresh={refresh} />;
      case userControlPanels.delete:
        return <DeletePanel selectedPlace={selectedPlace} refresh={refresh} />;
      case userControlPanels.findNearest:
        return <FindNearestPanel selectedPlace={selectedPlace} setSelectedPlace={setSelectedPlace} />;
      case userControlPanels.photos:
        return <PhotosPanel selectedPlace={selectedPlace} />;
      case userControlPanels.ranking:
        return <RankingPanel />;
      case userControlPanels.rate:
        return <RatePanel selectedPlace={selectedPlace} refresh={refresh} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="user-container">
        <div className="map-container">
          <MapWithPopup places={places} selectedPlace={selectedPlace} setSelectedPlace={setSelectedPlace} setActivePanel={openPanel} />
        </div>

        <div className={"controls" + (controlsActive ? " active" : "")}>
          <div className="buttons-container">
            <Button onClick={() => openPanel(userControlPanels.addEdit)}>{selectedPlace?.id ? "Edytuj miejsce" : "Dodaj miejsce"}</Button>
            <Button onClick={() => openPanel(userControlPanels.findNearest)}>Najbliższe miejsce</Button>
            <Button onClick={() => openPanel(userControlPanels.ranking)}>Ranking</Button>
          </div>
          <div className="control-panel">{renderPanel()}</div>
          <div className="close-controls-button-container">
            <i className="close-controls-button fa-solid fa-xmark" onClick={() => setControlsActive(false)}></i>
          </div>
        </div>
      </div>
    </>
  );
}
