export const callJsonApi = async (url, method, body = null) => {
  let status = 500;
  let data = null;

  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        SameSite: "none",
      },
      body: method !== "GET" && body !== null ? JSON.stringify(body) : null,
      credentials: "include",
    });

    status = response.status;
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      data = await response.json();
    } else {
      data = await response.text();
    }
  } catch (error) {
    console.error("Błąd: ", error);
  }

  return { status, data };
};
