import { nearestPlace } from "../../../../services/PlaceService";
import Button from "../../../button/Button";
import TextBox from "../../../textBox/TextBox";

export default function FindNearestPanel({ selectedPlace, setSelectedPlace }) {
  const findNearestPlace = async () => {
    if (!selectedPlace) {
      return;
    }
    const nearestPlaceBody = {
      longitude: selectedPlace.longitude,
      latitude: selectedPlace.latitude,
    };
    const result = await nearestPlace(nearestPlaceBody);
    if (result) {
      setSelectedPlace(result);
    }
  };

  return (
    <div className="panel-centered">
      <h1>Znajdź najbliższe miejsce</h1>
      <TextBox placeholder="Długość geograficzna..." value={selectedPlace?.longitude?.toFixed(6) ?? ""} />
      <TextBox placeholder="Szerokość geograficzna..." value={selectedPlace?.latitude?.toFixed(6) ?? ""} />
      <Button onClick={findNearestPlace}>Znajdź miejsce</Button>
    </div>
  );
}
