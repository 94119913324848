import "./TextBox.css";

const TextBox = ({ id, className, placeholder, value, onChange, readOnly, type }) => {
  if (!onChange) {
    readOnly = true;
  }

  let tbClass = "textBox";
  if (className) {
    tbClass += " " + className;
  }

  return (
    <input
      id={id ?? null}
      className={tbClass}
      type={type ?? "text"}
      placeholder={placeholder}
      value={value ?? undefined}
      onChange={onChange}
      readOnly={readOnly}
    />
  );
};

export default TextBox;
