import { Link } from "react-router-dom";
import "./Navbar.css";
import { useAuth } from "../../auth/AuthProvider";
import { useEffect, useState } from "react";
import Hamburger from "../hamburger/Hamburger";
import Button from "../button/Button";

export default function Navbar() {
  const { loading, username, role, logout } = useAuth();
  const [navbarItems, setNavbarItems] = useState(null);

  const [active, setActive] = useState(false);
  const handleClick = () => setActive((prev) => !prev);
  let activeClass = active ? " active" : "";

  useEffect(() => {
    if (!loading && username) {
      setNavbarItems(
        <>
          {role === "Admin" && (
            <Button
              link="/admin"
              buttonStyle="btn-outline"
              onClick={handleClick}
            >
              ADMIN
            </Button>
          )}
          <Button link="/" buttonStyle="btn-outline" onClick={handleClick}>
            PANEL
          </Button>
          <Button
            link="/events"
            buttonStyle="btn-outline"
            onClick={handleClick}
          >
            WYDARZENIA
          </Button>
          <Button
            link="/settings"
            buttonStyle="btn-outline"
            onClick={handleClick}
          >
            USTAWIENIA
          </Button>
          <Button
            buttonStyle="btn-outline"
            onClick={() => {
              logout();
              handleClick();
            }}
          >
            WYLOGUJ
          </Button>
        </>
      );
    } else {
      setNavbarItems(
        <Button link="/login" buttonStyle="btn-outline" onClick={handleClick}>
          ZALOGUJ
        </Button>
      );
    }
  }, [username, role, loading, logout]);
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <Link to="/">
            <img src="/img/logo.png" alt="logo" />
          </Link>
        </div>
        <div className={"navbar-controls" + activeClass}>{navbarItems}</div>
        <Hamburger active={active} onClick={handleClick} />
      </div>
    </nav>
  );
}
