import "./Events.css";
import { useState, useEffect, useMemo } from "react";
import { getAllCurrentEvents, getAllUpcomingEvents, getAllPastEvents } from "../../../services/EventService";
import { getPlaces } from "../../../services/PlaceService";
import Button from "../../button/Button";
import TextBox from "../../textBox/TextBox";
import SelectBox from "../../selectBox/SelectBox";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const ViewMode = {
  CURRENT: "current",
  UPCOMING: "upcoming",
  ARCHIVE: "archive",
};

const SortMode = {
  START_DATE: "startDate",
  END_DATE: "endDate",
  NAME: "name",
  PLACE: "place",
};

const sortOptions = [
  { value: SortMode.START_DATE, label: "Sortuj po dacie rozpoczęcia" },
  { value: SortMode.END_DATE, label: "Sortuj po dacie zakończenia" },
  { value: SortMode.NAME, label: "Sortuj po nazwie" },
  { value: SortMode.PLACE, label: "Sortuj po miejscu" },
];

export default function Events() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [places, setPlaces] = useState([]);
  const [viewMode, setViewMode] = useState(ViewMode.CURRENT);
  const [searchQuery, setSearchQuery] = useState("");
  const [events, setEvents] = useState([]);
  const [sortMode, setSortMode] = useState(SortMode.START_DATE);
  const [sortDirection, setSortDirection] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      let fetchedEvents;
      switch (viewMode) {
        case ViewMode.CURRENT:
          fetchedEvents = await getAllCurrentEvents(selectedPlace?.id);
          break;
        case ViewMode.UPCOMING:
          fetchedEvents = await getAllUpcomingEvents(selectedPlace?.id);
          break;
        case ViewMode.ARCHIVE:
          fetchedEvents = await getAllPastEvents(selectedPlace?.id);
          break;
        default:
          fetchedEvents = [];
          break;
      }
      setEvents(fetchedEvents);
    };

    fetchEvents();
  }, [viewMode, selectedPlace?.id]);

  useEffect(() => {
    getPlaces().then(data => setPlaces(data));
  }, []);

  useEffect(() => {
    if (!places) {
      return;
    }

    let place = null;

    const placeId = searchParams.get("placeid");
    if (placeId) {
      place = places.find(place => place.id.toString() === placeId);
    }

    setSelectedPlace(place);
  }, [searchParams, places, location.search]);

  const formatDateTime = dateTime => {
    const date = new Date(dateTime);
    return date.toLocaleString("pl-PL");
  };

  const filteredAndSortedEvents = useMemo(() => {
    if (!events) {
      return [];
    }

    let filtered = events.filter(event => {
      const place = places.find(place => place.id === event.placeId);

      return (
        event.name?.toLowerCase().includes(searchQuery) ||
        event.description?.toLowerCase().includes(searchQuery) ||
        formatDateTime(event.startDate).includes(searchQuery) ||
        formatDateTime(event.endDate).includes(searchQuery) ||
        place?.name?.toLowerCase().includes(searchQuery)
      );
    });

    switch (sortMode) {
      case SortMode.START_DATE:
        filtered = filtered.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
        break;
      case SortMode.END_DATE:
        filtered = filtered.sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
        break;
      case SortMode.NAME:
        filtered = filtered.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case SortMode.PLACE:
        filtered = filtered.sort((a, b) => {
          const placeA = places.find(place => place.id === a.placeId);
          const placeB = places.find(place => place.id === b.placeId);
          return placeA.name.localeCompare(placeB.name);
        });
        break;
      default:
        break;
    }

    if (!sortDirection) {
      filtered.reverse();
    }

    return filtered;
  }, [events, places, searchQuery, sortMode, sortDirection]);

  const getPlaceName = placeId => {
    const place = places.find(place => place.id === placeId);
    return place ? place.name : "Nieznane miejsce";
  };

  return (
    <>
      <div className="events-container">
        <h2>
          {selectedPlace && (
            <>
              <span style={{ color: "var(--color-button)" }}>{selectedPlace.name}</span> -{" "}
            </>
          )}
          Wydarzenia {viewMode === ViewMode.CURRENT ? "trwające" : viewMode === ViewMode.UPCOMING ? "nadchodzące" : "archiwalne"}
        </h2>
        <div className="events-controls">
          <Button onClick={() => setViewMode(ViewMode.CURRENT)}>Trwające</Button>
          <Button onClick={() => setViewMode(ViewMode.UPCOMING)}>Nadchodzące</Button>
          <Button onClick={() => setViewMode(ViewMode.ARCHIVE)}>Archiwum</Button>
          <TextBox className="input-search" placeholder="Szukaj wydarzenia" onChange={e => setSearchQuery(e.target.value.toLowerCase())} />
          <SelectBox className="sorting-select" options={sortOptions} value={sortMode} onChange={e => setSortMode(e.target.value)} />
          <Button onClick={() => setSortDirection(sortDirection === true ? false : true)}>
            {sortDirection ? <i className="fa-solid fa-arrow-up"></i> : <i className="fa-solid fa-arrow-down"></i>}
          </Button>
        </div>
        <table className="events-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nazwa</th>
              <th>Miejsce</th>
              <th>Opis</th>
              <th>Start</th>
              <th>Koniec</th>
            </tr>
          </thead>
          <tbody>
            {filteredAndSortedEvents &&
              filteredAndSortedEvents.map(event => (
                <tr key={event.id} onClick={() => navigate("/event/" + event.id)}>
                  <td data-header="ID: ">{event.id}</td>
                  <td>{event.name}</td>
                  <td>{getPlaceName(event.placeId)}</td>
                  <td>{event.description}</td>
                  <td>{formatDateTime(event.startDate)}</td>
                  <td>{formatDateTime(event.endDate)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
