import apiConfig from "../config/apiConfig";
import { callJsonApi } from "../util/ApiCaller";

export async function getPlaceEvents(placeId) {
  const { status, data } = await callJsonApi(apiConfig.place + `/${placeId}/events`, "GET");
  if (status !== 200) {
    return null;
  }
  return data;
}

export async function getUpcomingEvent(placeId) {
  const { status, data } = await callJsonApi(apiConfig.place + `/${placeId}/events/upcoming`, "GET");
  if (status !== 200) {
    return null;
  }
  return data;
}

export async function getEventById(eventId) {
  const { status, data } = await callJsonApi(apiConfig.event + `/${eventId}`, "GET");
  if (status !== 200) {
    return null;
  }
  return data;
}

export async function createEvent(data) {
  return await callJsonApi(apiConfig.event, "POST", data);
}

export async function updateEvent(eventId, data) {
  const { status } = await callJsonApi(apiConfig.event + `/${eventId}`, "PUT", data);
  return status === 200;
}

export async function deleteEvent(eventId) {
  const { status } = await callJsonApi(apiConfig.event + `/${eventId}`, "DELETE");
  return status === 200;
}

export async function getParticipants(eventId) {
  const { status, data } = await callJsonApi(apiConfig.event + `/${eventId}/participants`, "GET");
  if (status !== 200) {
    return null;
  }
  return data;
}

export async function getAllUpcomingEvents(placeId = null) {
  let url = apiConfig.event + "/upcoming";
  if (placeId) {
    url += `?placeId=${placeId}`;
  }

  const { status, data } = await callJsonApi(url, "GET");
  if (status !== 200) {
    return null;
  }
  return data;
}

export async function getAllCurrentEvents(placeId = null) {
  let url = apiConfig.event + "/current";
  if (placeId) {
    url += `?placeId=${placeId}`;
  }

  const { status, data } = await callJsonApi(url, "GET");
  if (status !== 200) {
    return null;
  }
  return data;
}

export async function getAllPastEvents(placeId = null) {
  let url = apiConfig.event + "/past";
  if (placeId) {
    url += `?placeId=${placeId}`;
  }

  const { status, data } = await callJsonApi(url, "GET");
  if (status !== 200) {
    return null;
  }
  return data;
}

export async function joinEvent(eventId) {
  const { status } = await callJsonApi(apiConfig.event + `/${eventId}/join`, "POST");
  return status === 200;
}

export async function leaveEvent(eventId) {
  const { status } = await callJsonApi(apiConfig.event + `/${eventId}/leave`, "POST");
  return status === 200;
}
