import "./EventDetails.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { deleteEvent, getEventById, updateEvent, getParticipants, joinEvent, leaveEvent, createEvent } from "../../../services/EventService";
import { getPlaceById } from "../../../services/PlaceService";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../auth/AuthProvider";
import TextBox from "../../textBox/TextBox";
import Button from "../../button/Button";

const statuses = {
  upcoming: {
    text: "nadchodzące",
    className: "upcoming-title",
  },
  ongoing: {
    text: "trwające",
    className: "ongoing-title",
  },
  finished: {
    text: "zakończone",
    className: "finished-title",
  },
};

const modes = {
  view: "view",
  edit: "edit",
  create: "create",
};

function getEventStatus(startDate, endDate) {
  const now = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (now < start) {
    return statuses.upcoming;
  } else if (now >= start && now <= end) {
    return statuses.ongoing;
  } else {
    return statuses.finished;
  }
}

export default function EventDetails({ createMode = false }) {
  const { id: eventId } = useParams();
  const [searchParams] = useSearchParams();
  const [placeName, setPlaceName] = useState(null);
  const [mode, setMode] = useState(createMode ? modes.create : modes.view);
  const [eventData, setEventData] = useState({});
  const [participants, setParticipants] = useState([]);
  const [isParticipating, setIsParticipating] = useState(false);
  const { loading, role, userId } = useAuth();
  const navigate = useNavigate();

  const refreshEvent = useCallback(async () => {
    let placeId = undefined;

    if (mode === modes.create) {
      placeId = searchParams.get("placeid");
      setEventData({ placeId: placeId });
    } else {
      const data = await getEventById(eventId);
      if (data) {
        placeId = data.placeId;
        setEventData(data);
      }
    }

    if (placeId) {
      getPlaceById(placeId).then(placeData => {
        setPlaceName(placeData?.name);
      });
    }
  }, [eventId, mode, searchParams]);

  const refreshParticipants = useCallback(() => {
    if (mode === modes.create) {
      return;
    }

    getParticipants(eventId).then(data => {
      if (data) {
        setParticipants(data);
        setIsParticipating(data.some(data => data.id === userId));
      }
    });
  }, [eventId, userId, mode]);

  const saveEvent = useCallback(() => {
    if (mode === modes.edit) {
      updateEvent(eventId, eventData).then(success => {
        if (success) {
          setMode(modes.view);
        }
      });
    } else if (mode === modes.create) {
      createEvent(eventData).then(response => {
        if (response.status === 201) {
          setMode(modes.view);
          navigate(`/event/${response.data}`);
        }
      });
    }
  }, [mode, eventId, eventData, navigate]);

  const handleDeleteEvent = useCallback(() => {
    deleteEvent(eventData.id).then(response => {
      if (response) {
        navigate("/");
      }
    });
  }, [eventData, navigate]);

  const handleJoinEvent = useCallback(() => {
    joinEvent(eventId).then(success => {
      if (success) {
        refreshParticipants();
      }
    });
  }, [eventId, refreshParticipants]);

  const handleLeaveEvent = useCallback(() => {
    leaveEvent(eventId).then(success => {
      if (success) {
        refreshParticipants();
      }
    });
  }, [eventId, refreshParticipants]);

  useEffect(() => {
    refreshEvent();
  }, [refreshEvent, mode]);

  useEffect(() => {
    if (userId) {
      refreshParticipants();
    }
  }, [userId, refreshParticipants]);

  const eventStatus = getEventStatus(eventData.startDate, eventData.endDate);

  const topSection = useMemo(() => {
    switch (mode) {
      case modes.view:
        return (
          <>
            <h2>
              Wydarzenie <span className={eventStatus.className}>{eventStatus.text}</span>
            </h2>
            <h1 className="event-name">{eventData.name}</h1>
          </>
        );
      case modes.edit:
      case modes.create:
        return (
          <>
            {mode === modes.edit ? <h2>Edytuj wydarzenie</h2> : <h2>Dodaj wydarzenie</h2>}

            <div className="input-group">
              <TextBox className="event-name" value={eventData.name || ""} onChange={e => setEventData({ ...eventData, name: e.target.value })} />
            </div>
          </>
        );
      default:
        return <></>;
    }
  }, [mode, eventData, eventStatus]);

  const buttons = useMemo(() => {
    let result;
    if (mode === modes.view && eventStatus !== statuses.finished) {
      result = (
        <>
          {!isParticipating ? (
            <Button onClick={handleJoinEvent}>Dołącz do wydarzenia</Button>
          ) : (
            <Button buttonStyle="btn-delete" onClick={handleLeaveEvent}>
              Opuść wydarzenie
            </Button>
          )}
        </>
      );

      if (!loading && (role === "Admin" || eventData.createdById === userId)) {
        result = (
          <>
            <Button onClick={() => setMode(modes.edit)}>Edytuj wydarzenie</Button>
            <Button onClick={handleDeleteEvent}>Usuń wydarzenie</Button>
            {result}
          </>
        );
      }
    } else if (mode === modes.edit || mode === modes.create) {
      result = <Button onClick={saveEvent}>{mode === modes.edit ? "Zapisz zmiany" : "Dodaj wydarzenie"}</Button>;

      if (mode === modes.edit) {
        result = (
          <>
            {result}
            <Button buttonStyle="btn-delete" onClick={() => setMode(modes.view)}>
              Anuluj
            </Button>
          </>
        );
      }
    }

    return <div className="event-details-buttons">{result}</div>;
  }, [mode, eventData, eventStatus, isParticipating, loading, role, userId, saveEvent, handleJoinEvent, handleLeaveEvent, handleDeleteEvent]);

  if (!eventData) {
    return <div>Ładowanie...</div>;
  }

  if (eventData === null) {
    return <div>Nie znaleziono wydarzenia.</div>;
  }

  return (
    <div className="event-details-wrapper">
      <div className="event-details-container">
        {topSection}
        <div className="input-group">
          <label htmlFor="description">Opis:</label>
          <TextBox
            id="description"
            placeholder="Opis wydarzenia..."
            value={eventData.description}
            onChange={e => setEventData({ ...eventData, description: e.target.value })}
            readOnly={mode === modes.view}
          />
        </div>
        <div className="input-group">
          <label htmlFor="startDate">Data rozpoczęcia:</label>
          <TextBox
            id="startDate"
            placeholder="Data rozpoczęcia wydarzenia..."
            type="datetime-local"
            value={eventData.startDate || ""}
            onChange={e => setEventData({ ...eventData, startDate: e.target.value })}
            readOnly={mode === modes.view}
          />
        </div>
        <div className="input-group">
          <label htmlFor="endDate">Data końca:</label>
          <TextBox
            id="endDate"
            placeholder="Data końca wydarzenia..."
            type="datetime-local"
            value={eventData.endDate || ""}
            onChange={e => setEventData({ ...eventData, endDate: e.target.value })}
            readOnly={mode === modes.view}
          />
        </div>
        <div className="input-group">
          <label htmlFor="placeName">Powiązane miejsce:</label>
          <TextBox id="placeName" placeholder="Powiązane miejsce..." value={placeName} readOnly />
        </div>

        {buttons}

        {mode !== modes.create && (
          <div className="participants-container">
            <h3>Użytkownicy biorący udział w wydarzeniu:</h3>
            <div className="participants-table">
              {participants.map(user => (
                <div key={user.id} className="user-row">
                  {user.username}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
