import "./RatePanel.css";
import { useState } from "react";
import { ratePlace } from "../../../../services/PlaceService";
import Button from "../../../button/Button";

export default function RatePanel({ selectedPlace, refresh }) {
  const [rating, setRating] = useState(null);

  const executeRate = async () => {
    if (!rating) {
      return;
    }

    const result = await ratePlace(selectedPlace.id, rating);
    if (result) {
      refresh();
    }
  };

  return (
    <div className="panel-centered">
      <h1>Oceń miejsce</h1>
      <h2 className="place-name">{selectedPlace.name}</h2>
      <div className="rating-stars-container">
        <p>Twoja ocena: {rating?.score ?? 1}/5</p>
        {[1, 2, 3, 4, 5].map(value => (
          <span
            key={value}
            className={`star ${value <= (rating?.score ?? 1) ? "star-filled" : ""}`}
            onClick={() => setRating({ ...rating, score: value })}
          >
            ★
          </span>
        ))}
      </div>
      <textarea className="comment-box" name="comment" placeholder="Komentarz..." onChange={e => setRating({ ...rating, text: e.target.value })} />
      <Button onClick={executeRate}>Oceń</Button>
    </div>
  );
}
